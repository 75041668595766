import "./App.css";

import Home from "./pages/Home";
import Register from "./components/Register";
import Login from "./components/Login";
import { Route, Routes } from "react-router-dom";
import ProtectedRoutes from "./components/auth/ProtectedRoutes;";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RoleList from "./components/roles/RoleList";
import UserList from "./components/users/UserList";
import Configuration from "./components/Configuration";
import UserForm from "./components/users/UserForm";
import { GlobalContextProvider } from "./cotext/GlobalContext";
import { Helmet } from "react-helmet";
import SuperAdminRoutes from "./components/auth/SuperAdminRoutes";
function App() {
  return (
    <GlobalContextProvider>
      <div className="app">
        <Helmet>
          <title>{"BI"}</title>
        </Helmet>
        <Routes>
          {/* delete this routes */}
          <Route path="/sign-up" element={<Register />} />
          {/* <Route path="/sign-in" element={<Login />} /> */}
          <Route path="/" element={<Login />} />
          {/* delete this routes */}

          <Route path="" element={<ProtectedRoutes />}>
            <Route path="/dashboard" element={<Home />} />
            <Route
              path="/user/profile"
              element={
                <UserForm sx={{ maxWidth: "500px", margin: "auto" }} profile />
              }
            />
            <Route path="" element={<SuperAdminRoutes />}>
              <Route path="/roles" element={<RoleList />} />
              <Route path="/configuration" element={<Configuration />} />
              <Route path="/users" element={<UserList />} />
            </Route>
          </Route>
        </Routes>
        <ToastContainer />
      </div>
    </GlobalContextProvider>
  );
}

export default App;
