import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  Paper,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import axios from "axios";
import React from "react";
import { useState, useEffect, useRef } from "react";
import CircularProgressGraph from "../../components/formUI/CircularProgressGraph";
import CustomTable from "../../components/formUI/CustomTable";
import PaperCard from "../../components/Layout/PaperCard";
import RevenueChart from "./RevenueChart";
import currencyFormatter from "currency-formatter";
import RevenuePieChart from "./RevenuePieChart";
import AdrMixGraph from "./AdrMixGraph";
import DownloadIcon from "@mui/icons-material/Download";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDummy from "../../assets/images/brand.png";
import {
  COLUMN_DUMMY_DATA,
  TABLE_DUMMY_DATA,
  TABLE_DUMMY_DATA_2,
  TABLE_DUMMY_DATA_3,
} from "../../dummy_data";
import { getExcelFileUrl, postExcelUrl } from "../../urls";
import FIleInput from "../../components/formUI/FIleInput";
import HomeHeader from "./HomeHeader";
import DailyRevenue from "./homeComponents/DailyRevenue";
import { useContext } from "react";
import { GlobalContext } from "../../cotext/GlobalContext";
import HomeBottomBar from "./HomeBottomBar";
import DailyRevenue2 from "./homeComponents/DailyRevenue2";

const TABLE_DATA = [
  {
    title: "Statistics : ",
    mtd_actual: "",
    mtd_budget: "",
    mtd_daily: "",
  },
  {
    title: "Occupancy",
    mtd_actual: "60%",
    mtd_budget: "75%",
    mtd_daily: "70%",
  },
  {
    title: "Avg Rate",
    mtd_actual: "100",
    mtd_budget: "150",
    mtd_daily: "200",
  },
  {
    title: "Revenue : ",
    mtd_actual: "",
    mtd_budget: "",
    mtd_daily: "",
  },
  {
    title: "Rooms Revenue ",
    mtd_actual: "99,999",
    mtd_budget: "59,999",
    mtd_daily: "39,999",
  },
  {
    title: "Food and Beverages Revenue",
    mtd_actual: "99,999",
    mtd_budget: "59,999",
    mtd_daily: "39,999",
  },
  {
    title: "Total Revenue",
    mtd_actual: "99,999",
    mtd_budget: "59,999",
    mtd_daily: "39,999",
  },
];

const TABLE_DATA_2 = [
  {
    title: "Statistics",
    mtd_actual: "60%",
    mtd_budget: "75%",
  },
];

const DATA = [
  {
    country: "AD",
    "hot dog": 121,
    "hot dogColor": "hsl(355, 70%, 50%)",
    burger: 66,
    burgerColor: "hsl(319, 70%, 50%)",
    sandwich: 193,
    sandwichColor: "hsl(62, 70%, 50%)",
    kebab: 19,
    kebabColor: "hsl(208, 70%, 50%)",
    fries: 143,
    friesColor: "hsl(215, 70%, 50%)",
    donut: 68,
    donutColor: "hsl(68, 70%, 50%)",
  },
  {
    country: "AE",
    "hot dog": 147,
    "hot dogColor": "hsl(303, 70%, 50%)",
    burger: 64,
    burgerColor: "hsl(242, 70%, 50%)",
    sandwich: 82,
    sandwichColor: "hsl(40, 70%, 50%)",
    kebab: 24,
    kebabColor: "hsl(317, 70%, 50%)",
    fries: 60,
    friesColor: "hsl(320, 70%, 50%)",
    donut: 181,
    donutColor: "hsl(308, 70%, 50%)",
  },
  {
    country: "AF",
    "hot dog": 171,
    "hot dogColor": "hsl(228, 70%, 50%)",
    burger: 89,
    burgerColor: "hsl(159, 70%, 50%)",
    sandwich: 110,
    sandwichColor: "hsl(73, 70%, 50%)",
    kebab: 137,
    kebabColor: "hsl(120, 70%, 50%)",
    fries: 54,
    friesColor: "hsl(200, 70%, 50%)",
    donut: 135,
    donutColor: "hsl(55, 70%, 50%)",
  },
  {
    country: "AG",
    "hot dog": 80,
    "hot dogColor": "hsl(109, 70%, 50%)",
    burger: 17,
    burgerColor: "hsl(352, 70%, 50%)",
    sandwich: 44,
    sandwichColor: "hsl(5, 70%, 50%)",
    kebab: 124,
    kebabColor: "hsl(15, 70%, 50%)",
    fries: 50,
    friesColor: "hsl(269, 70%, 50%)",
    donut: 67,
    donutColor: "hsl(212, 70%, 50%)",
  },
  {
    country: "AI",
    "hot dog": 97,
    "hot dogColor": "hsl(17, 70%, 50%)",
    burger: 101,
    burgerColor: "hsl(331, 70%, 50%)",
    sandwich: 113,
    sandwichColor: "hsl(307, 70%, 50%)",
    kebab: 170,
    kebabColor: "hsl(191, 70%, 50%)",
    fries: 53,
    friesColor: "hsl(190, 70%, 50%)",
    donut: 139,
    donutColor: "hsl(251, 70%, 50%)",
  },
  {
    country: "AL",
    "hot dog": 14,
    "hot dogColor": "hsl(344, 70%, 50%)",
    burger: 52,
    burgerColor: "hsl(218, 70%, 50%)",
    sandwich: 104,
    sandwichColor: "hsl(90, 70%, 50%)",
    kebab: 7,
    kebabColor: "hsl(347, 70%, 50%)",
    fries: 100,
    friesColor: "hsl(321, 70%, 50%)",
    donut: 175,
    donutColor: "hsl(9, 70%, 50%)",
  },
  {
    country: "AM",
    "hot dog": 123,
    "hot dogColor": "hsl(283, 70%, 50%)",
    burger: 111,
    burgerColor: "hsl(343, 70%, 50%)",
    sandwich: 24,
    sandwichColor: "hsl(232, 70%, 50%)",
    kebab: 58,
    kebabColor: "hsl(110, 70%, 50%)",
    fries: 98,
    friesColor: "hsl(307, 70%, 50%)",
    donut: 156,
    donutColor: "hsl(299, 70%, 50%)",
  },
];

const COLUMNS_DATA = ["Revenue", "F/B Revenue", "OOD Revenue", "Total Revenue"];
const COLUMNS_DATA_2 = [
  "Description",
  "Month to Date",
  "",
  "",
  "Year to Date",
  "",
  "",
];
const COLUMNS_DATA_3 = ["Revenue Stream (MTD)", "Actual", "Percent"];
const COLUMNS_DATA_4 = ["Revenue Stream (YTD)", "Actual", "Percent"];

const Home = () => {
  const { permissions } = useContext(GlobalContext);
  // const [permissions, setPermissions] = useState([]);
  // useEffect(() => {
  //   setPermissions(accessHandler(getProfile()));
  // }, []);
  // console.log({ permissions });
  const [dashboardData, setDashboardData] = useState(null);
  const revenueTable = { ...dashboardData };

  useEffect(() => {
    axios
      .get("http://192.168.1.38:9000/data_reports/daily")
      .then((res) => setDashboardData(res.data));
  }, []);
  delete revenueTable?.Occupancy;
  delete revenueTable?.ADR;

  const getFormatCurrency = (val) => currencyFormatter.format(val, {});

  const uploadFileHandler = (e) => {
    // "file : ", e.target.files[0];
    // setFile(e.target.files[0])
    // axios.post(postExcelUrl,{file :e.target.files[0] })
  };

  return (
    <Grid
      container
      spacing={2}
      // pl={2}
      // pr={2}
      sx={{
        // paddingLeft: "6rem !important",
        // paddingRight: "1rem !important",
        // // background: `linear-gradient(180deg, #0b2b4b 0%, #1976d2 60%)`,
        // // background: `linear-gradient(180deg, #0b2b4b 0%, #1976d2 60%)`,
        // background: "linear-gradient(180deg, #0d3c6c 0%, #3d8bd8 60%)",

        // background: "linear-gradient(180deg, #1976d2 0%, #c8e4ff 100%)",
        backgroundAttachment: "fixed",
        backgroundSize: "100% 100vh",
        "& > *": {
          // maxWidth: { xs: "60vw", sm: "100%" },
          // minWidth: { xs: "60vw", sm: "100%" },
          // width: { xs: "60vw", sm: "100%" },
        },
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          position: { xs: "relative", md: "sticky" },
          alignSelf: "flex-start",
          top: { xs: 0, md: 58 },
          zIndex: 999,
        }}
      >
        {/* <HomeHeader /> */}
      </Grid>
      {/* Daily Revenue */}
      {permissions.includes("Revenue") ? (
        <DailyRevenue2
          {...{
            dashboardData,
            getFormatCurrency,
            COLUMNS_DATA,
            TABLE_DATA,
            revenueTable,
            COLUMN_DUMMY_DATA,
          }}
        />
      ) : null}
      {/* Daily Revenue */}
      <Grid item xs={12}>
        <PaperCard title={"NOP & GOP"}>
          <CustomTable
            columns={COLUMNS_DATA_2}
            data={TABLE_DATA}
            SubtTableHead={
              <TableRow
                sx={{
                  "& > *": {
                    fontWeight: 600,
                  },
                }}
              >
                <TableCell></TableCell>
                <TableCell>Actual</TableCell>
                <TableCell>Budget</TableCell>
                <TableCell>LY</TableCell>
                <TableCell>Actual YTD</TableCell>
                <TableCell>Budget YTD</TableCell>
                <TableCell>LY YTD</TableCell>
              </TableRow>
            }
          >
            {TABLE_DUMMY_DATA?.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.title}</TableCell>
                <TableCell>{row.mtd_actual}</TableCell>
                <TableCell>{row.mtd_budget}</TableCell>
                <TableCell>{row.mtd_daily}</TableCell>{" "}
                <TableCell>{row.mtd_actual}</TableCell>
                <TableCell>{row.mtd_budget}</TableCell>
                <TableCell>{row.mtd_daily}</TableCell>
              </TableRow>
            ))}
          </CustomTable>
        </PaperCard>
      </Grid>
      <Grid item xs={12}>
        <PaperCard
          paperSx={{ background: "transparent" }}
          sx={{ height: { xs: "auto", md: "500px" } }}
          title="Current Year ADR"
        >
          <AdrMixGraph />
        </PaperCard>
      </Grid>{" "}
      <Grid item xs={12}>
        <PaperCard sx={{ height: { xs: "auto", md: "500px" } }}>
          Last Year ADR
          <AdrMixGraph />
        </PaperCard>
      </Grid>{" "}
      <Grid item xs={12}>
        <PaperCard sx={{ height: "500px" }}>
          Yearly Revenue Chart
          <br />
          <Box
            sx={{
              height: { xs: "500px", md: "500px" },
              maxWidth: { xs: "70vw", md: "auto" },
              margin: "auto",
              overflowY: "hidden",
              overflowX: "auto",
            }}
          >
            <Box sx={{ width: "500px", height: "500px" }}>
              <RevenueChart type="2" />
            </Box>
          </Box>
        </PaperCard>
      </Grid>{" "}
      <Grid item xs={12}>
        <PaperCard sx={{ height: "auto" }}>
          Revenue
          <br />
          <br />
          <Divider />
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={6}>
              <CustomTable columns={COLUMNS_DATA_3} data={TABLE_DATA_2}>
                {TABLE_DUMMY_DATA_2?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.actual}</TableCell>
                    <TableCell>{row.percent}</TableCell>
                  </TableRow>
                ))}
              </CustomTable>
              <Box
                sx={{
                  height: { xs: "500px", md: "500px" },
                  maxWidth: { xs: "70vw", md: "auto" },
                  margin: "auto",
                  overflowY: "hidden",
                  overflowX: "auto",
                }}
              >
                <Box sx={{ width: "500px", height: "500px" }}>
                  <RevenuePieChart />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <CustomTable columns={COLUMNS_DATA_4} data={TABLE_DATA_2}>
                {TABLE_DUMMY_DATA_2?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.actual}</TableCell>
                    <TableCell>{row.percent}</TableCell>
                  </TableRow>
                ))}
              </CustomTable>
              <Box
                sx={{
                  height: { xs: "500px", md: "500px" },
                  maxWidth: { xs: "70vw", md: "auto" },
                  margin: "auto",
                  overflowY: "hidden",
                  overflowX: "auto",
                }}
              >
                <Box sx={{ width: "500px", height: "500px" }}>
                  <RevenuePieChart />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </PaperCard>
      </Grid>{" "}
      <Grid item xs={12} md={12} lg={12}>
        <PaperCard>
          Profitablity
          <br />
          <br />
          <Grid container spacing={4}>
            <Grid item xs={12} md={12} lg={6}>
              <CustomTable
                columns={COLUMN_DUMMY_DATA[1]}
                data={TABLE_DATA_2}
                SubtTableHead={
                  <TableRow
                    sx={{
                      "& > *": {
                        fontWeight: 600,
                      },
                    }}
                  >
                    {COLUMN_DUMMY_DATA[2].map((elt, index) => (
                      <TableCell key={index}>{elt}</TableCell>
                    ))}
                  </TableRow>
                }
              >
                {TABLE_DUMMY_DATA_3?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.actual}</TableCell>
                    <TableCell>{row.budget}</TableCell>
                    <TableCell>{row.last_year}</TableCell>
                  </TableRow>
                ))}
              </CustomTable>
            </Grid>{" "}
            <Grid item xs={12} md={12} lg={6}>
              <CustomTable
                columns={COLUMN_DUMMY_DATA[1]}
                data={TABLE_DATA_2}
                SubtTableHead={
                  <TableRow
                    sx={{
                      "& > *": {
                        fontWeight: 600,
                      },
                    }}
                  >
                    {COLUMN_DUMMY_DATA[2].map((elt, index) => (
                      <TableCell key={index}>{elt}</TableCell>
                    ))}
                  </TableRow>
                }
              >
                {TABLE_DUMMY_DATA_3?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.actual}</TableCell>
                    <TableCell>{row.budget}</TableCell>
                    <TableCell>{row.last_year}</TableCell>
                  </TableRow>
                ))}
              </CustomTable>
            </Grid>
          </Grid>
        </PaperCard>
      </Grid>
      <HomeBottomBar />
    </Grid>
  );
};

export default Home;
