import { createContext, useEffect, useState } from "react";
import axios from "axios";
import { roleUrl, userUrl } from "../urls";
const initialState = {
  userlist: [],
  roleList: [],
};
const UserIntialState = {
  username: "",
  password: "",
  email: "",
  role: null,
  pl_actual: false,
  pl_budget: false,
  revenue_actual: false,
  revenue_budget: false,
  str_report: false,
  daily_revenue: false,
  monthly_revenue: false,
};
const RoleIntialState = {
  role_name: "",
};
export const GlobalContext = createContext(initialState);

export const GlobalContextProvider = ({ children }) => {
  const [userList, setUserList] = useState([]);
  const [roleList, setRoleList] = useState([]);

  const [user, setUser] = useState(UserIntialState);
  const [role, setRole] = useState(RoleIntialState);
  const [profile, setProfile] = useState(null);
  const [permissions, setPermissions] = useState([]);

  const accessHandler = (user) => {
    let accessArray = [];

    const {
      pl_actual,
      pl_budget,
      revenue_actual,
      revenue_budget,
      str_report,
      daily_revenue,
      monthly_revenue,
    } = user;
    const reportsArray = [
      "Revenue",
      "Monthly Revenue",
      "STR",
      "PL Actual",
      "PL Budget",
      "Revenue Actual",
      "Revenue Budget",
    ];

    if (daily_revenue) accessArray.push(reportsArray[0]);
    if (monthly_revenue) accessArray.push(reportsArray[1]);
    if (str_report) accessArray.push(reportsArray[2]);
    if (pl_actual) accessArray.push(reportsArray[3]);
    if (pl_budget) accessArray.push(reportsArray[4]);
    if (revenue_actual) accessArray.push(reportsArray[5]);
    if (revenue_budget) accessArray.push(reportsArray[6]);

    return accessArray;
  };
  const saveProfile = (p, token) => {
    localStorage.setItem("user-data", JSON.stringify(p));
    if (token) localStorage.setItem("token", JSON.stringify(token));
    setPermissions(accessHandler(p));
    setProfile(p);
  };
  const getProfile = () => {
    // made changes here to resolve 91 line bug
    const userData = localStorage.getItem("user-data");
    if (userData === "undefined") return {};
    return JSON.parse(localStorage.getItem("user-data"));
    // return 0;
  };
  useEffect(() => {
    if (localStorage.getItem("token"))
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("token").slice(1, -1);
  }, []);

  const deleteProfile = () => {
    localStorage.removeItem("user-data");
    localStorage.removeItem("token");
  };
  const isSuperAdmin = () => getProfile().id === 1 || getProfile().id === 2;

  useEffect(() => {
    profile && saveProfile(profile);
  }, [profile]);

  useEffect(() => {
    // here bug arrised
    if (getProfile()?.id)
      axios.get(userUrl + getProfile().id).then((res) => {
        saveProfile(res.data.data);
      });
  }, []);
  useEffect(() => {
    if (getProfile()) setPermissions(accessHandler(getProfile()));
  }, []);

  // const saveProfile = p;

  const fetchUserList = () => {
    axios.get(userUrl).then((res) => setUserList(res.data.data));
  };

  const formDataHandler = (e, of) =>
    of === "user"
      ? setUser({
          ...user,
          [e.target.name]:
            e.target.type === "checkbox" ? e.target.checked : e.target.value,
        })
      : of === "role"
      ? setRole({
          ...role,
          [e.target.name]:
            e.target.type === "checkbox" ? e.target.checked : e.target.value,
        })
      : null;

  const resetUser = () => setUser(UserIntialState);

  const onSubmitUserForm = (e, defaultData, profile, handleClose) => {
    // (defaultData || profile) &&
    e.preventDefault();
    if (defaultData || profile) {
      axios
        .put(userUrl + user.id, user)
        .then((res) => {
          if (profile) setProfile(user);
          // console.log(res);
          !profile
            ? setUser(UserIntialState)
            : localStorage.setItem("user-data", JSON.stringify(user));
          handleClose && handleClose();
          alert("User Updated!");
          !profile && fetchUserList();
        })
        .catch((err) => {
          alert("Something went wrong!");
        });
    } else {
      axios
        .post(userUrl, user)
        .then((res) => {
          if (profile) setProfile(user);
          setUser((prevUser) => {
            // console.log({ prevUser, UserIntialState });
            return UserIntialState;
          });
          alert("User Created!");
        })
        .catch((err) => alert("Something went wrong!"));
    }
  };

  const onSubmitRoleForm = (e, defaultData, handleClose) => {
    if (!role.role_name) alert("Please fill details");
    e.preventDefault();
    if (!defaultData) {
      axios
        .post(roleUrl, role)
        .then((res) => {
          resetRole();
          alert("Role Created!");
          fetchRoleList();
        })
        .catch((err) => alert("Something went wrong!"));
    } else {
      axios
        .put(roleUrl + role.id, role)
        .then((res) => {
          handleClose && handleClose();
          alert("Role Updated!");
          fetchRoleList();
        })
        .catch((err) => alert("Something went wrong!"));
    }
  };

  const fetchRoleList = () => {
    axios.get(roleUrl).then((res) => setRoleList(res.data.data));
  };
  const resetRole = () => setRole(RoleIntialState);

  //   useEffect(() => {
  //     fetchUserList();
  //     fetchRoleList();
  //   }, []);

  return (
    <GlobalContext.Provider
      value={{
        userList,
        fetchUserList,
        fetchRoleList,
        roleList,
        onSubmitUserForm,
        onSubmitRoleForm,
        formDataHandler,
        user,
        setUser,
        resetUser,
        role,
        setRole,
        resetRole,
        saveProfile,
        getProfile,
        deleteProfile,
        profile,
        accessHandler,
        isSuperAdmin,
        permissions,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
