import React from "react";
import { useContext } from "react";

import { Navigate, Outlet } from "react-router-dom";
import { GlobalContext } from "../../cotext/GlobalContext";

const SuperAdminRoutes = () => {
  const { isSuperAdmin } = useContext(GlobalContext);
  return isSuperAdmin() ? <Outlet /> : <Navigate to="" />;
};

export default SuperAdminRoutes;
