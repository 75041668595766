import { ResponsiveBar } from "@nivo/bar";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const data = [
  {
    country: "MTD Actual",
    Average: 59,
    AverageColor: "hsl(228, 70%, 50%)",
    Revenue: 120,
    RevenueColor: "hsl(228, 70%, 50%)",
    Occupancy: 109,
    OccupancyColor: "hsl(228, 70%, 50%)",
    kebab: 86,
    kebabColor: "hsl(209, 70%, 50%)",
  },
  {
    country: "MTD Revenue",
    Average: 152,
    AverageColor: "hsl(308, 70%, 50%)",
    Revenue: 96,
    RevenueColor: "hsl(87, 70%, 50%)",
    Occupancy: 160,
    OccupancyColor: "hsl(312, 70%, 50%)",
    kebab: 13,
    kebabColor: "hsl(176, 70%, 50%)",
  },
  {
    country: "MTD LY",
    Average: 81,
    AverageColor: "hsl(39, 70%, 50%)",
    Revenue: 75,
    RevenueColor: "hsl(201, 70%, 50%)",
    Occupancy: 158,
    OccupancyColor: "hsl(212, 70%, 50%)",
    kebab: 126,
    kebabColor: "hsl(100, 70%, 50%)",
  },
];
const data2 = [
  {
    country: "MTD Actual",
    "Total Revenue": 59,
    "Total RevenueColor": "rgb(75, 153, 20)",

    Occupancy: 109,
    OccupancyColor: "hsl(228, 70%, 50%)",
    GOP: 86,
    GOPColor: "hsl(209, 70%, 50%)",
    NOP: 86,
    NOPColor: "hsl(209, 70%, 50%)",
  },
  {
    country: "MTD Revenue",
    "Total Revenue": 152,
    "Total RevenueColor": "hsl(308, 70%, 50%)",

    Occupancy: 160,
    OccupancyColor: "hsl(312, 70%, 50%)",
    GOP: 13,
    GOPColor: "hsl(176, 70%, 50%)",
    NOP: 86,
    NOPColor: "hsl(209, 70%, 50%)",
  },
  {
    country: "MTD LY",
    "Total Revenue": 81,
    AverageColor: "hsl(39, 70%, 50%)",

    Occupancy: 158,
    OccupancyColor: "hsl(212, 70%, 50%)",
    GOP: 126,
    GOPColor: "hsl(100, 70%, 50%)",
    NOP: 86,
    NOPColor: "hsl(209, 70%, 50%)",
  },
];
const MyResponsiveBar = ({ type = 1 }) => (
  <ResponsiveBar
    data={type == 1 ? data : data2}
    keys={
      type == 1
        ? ["Average", "Revenue", "Occupancy"]
        : ["Total Revenue", "GOP", "NOP"]
    }
    // keys={["Average", "Revenue", "Occupancy"]}
    indexBy="country"
    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
    padding={0.5}
    valueScale={{ type: "linear" }}
    indexScale={{ type: "band", round: true }}
    // colors={{ scheme: "nivo" }}
    borderColor={{
      from: "color",
      modifiers: [["darker", 1.6]],
    }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      // legend: "country",
      legendPosition: "middle",
      legendOffset: 32,
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      // legend: "food",
      legendPosition: "middle",
      legendOffset: -40,
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{
      from: "color",
      modifiers: [["darker", 1.6]],
    }}
    groupMode="grouped"
    legends={[
      {
        dataFrom: "keys",
        anchor: "bottom-right",
        direction: "column",
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: "left-to-right",
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: "hover",
            style: {
              itemOpacity: 1,
            },
          },
        ],
      },
    ]}
    role="application"
    ariaLabel="Nivo bar chart demo"
    barAriaLabel={function (e) {
      return e.id + ": " + e.formattedValue + " in country: " + e.indexValue;
    }}
  />
);

export default MyResponsiveBar;
