import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect } from "react";
import CustomInput from "../../formUI/CustomInput";
import CustomSelect from "../../formUI/CustomSelect";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useContext } from "react";
import { GlobalContext } from "../../../cotext/GlobalContext";
import CustomCheckbox from "../../formUI/CustomCheckbox";
const UserForm = ({
  modal = false,
  sx = {},
  defaultData = null,
  profile = false,
  handleClose,
  ...otherProps
}) => {
  // const intialState = {
  //   username: "",
  //   password: "",
  //   email: "",
  //   role: null,
  // };
  // const [formData, setFormData] = useState(defaultData || intialState);
  // const [roleList, setRoleList] = useState([]);
  const {
    roleList,
    user,
    setUser,
    formDataHandler,
    fetchRoleList,
    onSubmitUserForm,
    getProfile,
  } = useContext(GlobalContext);
  const {
    username,
    password,
    email,
    role,
    pl_actual,
    pl_budget,
    revenue_actual,
    revenue_budget,
    str_report,
    daily_revenue,
    monthly_revenue,
  } = user;

  useEffect(() => {
    fetchRoleList();
    if (defaultData) setUser(defaultData);
    // console.log({ defaultData });
    if (profile) setUser({ ...getProfile() });
  }, [defaultData]);
  // const formDataHandler = (e) =>
  //   setFormData({ ...formData, [e.target.name]: e.target.value });

  // console.log(roleList);
  // useEffect(() => {
  //   axios.get(roleUrl).then((res) => setRoleList(res.data.data));
  // }, []);

  const onSubmit = (e) => {
    onSubmitUserForm(e, defaultData, profile, handleClose);
    // e.preventDefault();
    // console.log({ user });
    // if (defaultData || profile) {
    //   axios
    //     .put(userUrl + user.id, user)
    //     .then((res) => {
    //       setUser(intialState);
    //       alert("User Updated!");
    //     })
    //     .catch((err) => alert("Something went wrong!"));
    // } else {
    //   axios
    //     .post(userUrl, user)
    //     .then((res) => {
    //       setUser(intialState);
    //       alert("User Created!");
    //     })
    //     .catch((err) => alert("Something went wrong!"));
    // }
  };

  return (
    <Grid
      item={defaultData && false}
      xs={12}
      md={6}
      component={defaultData ? Box : Paper}
      px={2}
      py={2}
      elevation={3}
      sx={{
        borderRadius: "20px",
        background: "rgba(255, 255, 255, 0.25)",
        boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
        backdropFilter: "blur( 5px )",
        webkitBackdropFilter: "blur( 5px )",
        border: "1px solid rgba( 255, 255, 255, 0.18 )",
        ...sx,
      }}
      {...otherProps}
    >
      {!modal ? (
        <>
          <Typography variant={"h4"}>
            {defaultData || profile
              ? "View/Update your Information"
              : "Add New User"}
          </Typography>
          <Typography variant="body2" mb={4}>
            Wser will have a role, and its permission to action application.
          </Typography>
        </>
      ) : null}
      <Stack spacing={2} component="form" onSubmit={onSubmit}>
        <CustomInput
          label="Enter Name"
          name="username"
          value={username}
          onChange={(e) => formDataHandler(e, "user")}
        />
        <CustomInput
          label="Enter Password"
          type="password"
          name="password"
          value={password}
          onChange={(e) => formDataHandler(e, "user")}
        />
        <CustomInput
          label="Enter Email"
          name="email"
          value={email}
          onChange={(e) => formDataHandler(e, "user")}
        />
        {!profile ? (
          <CustomSelect
            label="Select Role"
            name="role"
            value={role}
            onChange={(e) => formDataHandler(e, "user")}
            menuItems={roleList}
          />
        ) : null}
        {!profile ? (
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body2">Permissions</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomCheckbox
                checked={daily_revenue}
                name={"daily_revenue"}
                onChange={(e) => formDataHandler(e, "user")}
                label="Daily Revenue Report"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomCheckbox
                checked={monthly_revenue}
                name={"monthly_revenue"}
                onChange={(e) => formDataHandler(e, "user")}
                label="Monthly Revenue Report"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomCheckbox
                checked={revenue_actual}
                name={"revenue_actual"}
                onChange={(e) => formDataHandler(e, "user")}
                label="Revenue Actual Report"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomCheckbox
                checked={revenue_budget}
                name={"revenue_budget"}
                onChange={(e) => formDataHandler(e, "user")}
                label="Revenue Budget Report"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomCheckbox
                checked={pl_actual}
                name={"pl_actual"}
                onChange={(e) => formDataHandler(e, "user")}
                label="PL Actual Report"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomCheckbox
                checked={pl_budget}
                name={"pl_budget"}
                onChange={(e) => formDataHandler(e, "user")}
                label="PL Budget Report"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomCheckbox
                checked={str_report}
                name={"str_report"}
                onChange={(e) => formDataHandler(e, "user")}
                label="STR Report"
              />
            </Grid>
          </Grid>
        ) : null}
        <Button
          type
          variant="contained"
          endIcon={<PersonAddIcon />}
          sx={{
            py: 1,
            px: 3,
            // alignSelf: "flex-start"
          }}
        >
          {!(defaultData || profile) ? "Add New User" : "Update User"}
        </Button>
      </Stack>
    </Grid>
  );
};

export default UserForm;
