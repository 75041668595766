import { Divider, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const PaperCard = ({ children, title, sx = {}, paperSx }) => {
  return (
    <Paper
      className="pp"
      sx={{
        // borderRadius: "30px !important",
        ...paperSx,
        // background: "linear-gradient(45deg, #fffcf7, #ffffffde)",
        // background: "linear-gradient(45deg, #ffffff, #b8dcff)",
        // background: "linear-gradient(45deg, #f5faff, #d3e9ff)",
        borderRadius: "20px !important",
        background: "rgba(255, 255, 255, 0.25)",
        boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
        backdropFilter: "blur( 5px )",
        webkitBackdropFilter: "blur( 5px )",
        border: "1px solid rgba( 255, 255, 255, 0.18 )",
      }}
      elevation={3}
    >
      <Box sx={{ pt: 2, px: 2, ...sx }}>
        {title ? (
          <>
            <Typography
              variant="h4"
              textAlign={"center"}
              pb={2}
              color="primary.dark"
            >
              {title || "Title Here"}
            </Typography>
            {/* <Divider /> */}
            {/* <br /> */}
          </>
        ) : null}
        {children}
      </Box>
    </Paper>
  );
};

export default PaperCard;
