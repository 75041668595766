export const COLUMN_DUMMY_DATA = [
  ["Description", "MTD Actual", "MTD Budget", "MTD LY"],
  ["Revenue Stream", "MTD", "", ""], //use this
  ["", "Actual", "Budget", "Last Year"], //use this as sub head
];

export const TABLE_DUMMY_DATA = [
  {
    title: "Statistics : ",
    mtd_actual: "",
    mtd_budget: "",
    mtd_daily: "",
  },
  {
    title: "Occupancy",
    mtd_actual: "60%",
    mtd_budget: "75%",
    mtd_daily: "70%",
  },
  {
    title: "Avg Rate",
    mtd_actual: "100",
    mtd_budget: "150",
    mtd_daily: "200",
  },
  {
    title: "Rev. Par",
    mtd_actual: "99,999",
    mtd_budget: "59,999",
    mtd_daily: "39,999",
  },
  {
    title: "Revenue : ",
    mtd_actual: "",
    mtd_budget: "",
    mtd_daily: "",
  },
  {
    title: "Room Revenue",
    mtd_actual: "99,999",
    mtd_budget: "59,999",
    mtd_daily: "39,999",
  },
  {
    title: "F & B Revenue",
    mtd_actual: "99,999",
    mtd_budget: "59,999",
    mtd_daily: "39,999",
  },
  {
    title: "OOD Revenue",
    mtd_actual: "99,999",
    mtd_budget: "59,999",
    mtd_daily: "39,999",
  },
  {
    title: "Total Revenue",
    mtd_actual: "99,999",
    mtd_budget: "59,999",
    mtd_daily: "39,999",
  },
  {
    title: "GOP",
    mtd_actual: "99,999",
    mtd_budget: "59,999",
    mtd_daily: "39,999",
  },
  {
    title: "NOP",
    mtd_actual: "99,999",
    mtd_budget: "59,999",
    mtd_daily: "39,999",
  },
];

export const TABLE_DUMMY_DATA_2 = [
  {
    title: "Rooms",
    actual: "8,345,123",
    percent: "70%",
  },
  {
    title: "Food & Beverages",
    actual: "8,345,123",
    percent: "70%",
  },
  {
    title: "Other Depts",
    actual: "8,345,123",
    percent: "70%",
  },
  {
    title: "Total Revenue",
    actual: "8,345,123",
    percent: "70%",
  },
];

export const TABLE_DUMMY_DATA_3 = [
  {
    title: "Occupancy",
    actual: "89%",
    budget: "80%",
    last_year: "80%",
  },
  {
    title: "Avg Rate",
    actual: "100",
    budget: "150",
    last_year: "130",
  },
  {
    title: "Rev PAR",
    actual: "100",
    budget: "150",
    last_year: "130",
  },
  {
    title: "Rooms Revenue",
    actual: "8,358,173",
    budget: "9,030,906",
    last_year: "8,735,292",
  },
  {
    title: "Food and Beverages",
    actual: "8,358,173",
    budget: "9,030,906",
    last_year: "8,735,292",
  },
  {
    title: "OOD Revenue",
    actual: "8,358,173",
    budget: "9,030,906",
    last_year: "8,735,292",
  },
  {
    title: "Total Revenue",
    actual: "8,358,173",
    budget: "9,030,906",
    last_year: "8,735,292",
  },
  {
    title: "GOP Revenue",
    actual: "8,358,173",
    budget: "9,030,906",
    last_year: "8,735,292",
  },
  {
    title: "NOP Revenue",
    actual: "8,358,173",
    budget: "9,030,906",
    last_year: "8,735,292",
  },
];
