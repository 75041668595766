import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CustomDialog from "../../components/formUI/CustomDialog";
import { useContext } from "react";
import { GlobalContext } from "../../cotext/GlobalContext";
import { useState } from "react";
import { Container, Stack } from "@mui/system";
import { blue, green } from "@mui/material/colors";
import DownloadIcon from "@mui/icons-material/Download";
const HomeBottomBar = () => {
  const [open, setOpen] = useState(false);
  const { permissions } = useContext(GlobalContext);

  const permissionActionList = permissions.map((permission) => (
    <Stack direction="row" alignItems="center" mb={1} ml={0}>
      <Typography>{permission}</Typography>
      <Avatar sx={{ bgcolor: green[500], ml: "auto" }} title="Upload CSV">
        <IconButton aria-label="delete" sx={{ color: "white" }}>
          <FileUploadIcon />
        </IconButton>
      </Avatar>
      <Avatar sx={{ bgcolor: blue[500], ml: 1 }} title="Download CSV">
        <IconButton aria-label="delete" sx={{ color: "white" }}>
          <DownloadIcon />
        </IconButton>
      </Avatar>
    </Stack>
  ));

  return (
    <Grid
      item
      xs={12}
      md={12}
      lg={12}
      component={{ ...Box, ...Paper }}
      display="flex"
      p={2}
      sx={{
        position: "sticky",
        bottom: 0,
        marginLeft: "1rem",
        background: "rgba( 0, 117, 255, 0.25 )",
        boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
        backdropFilter: "blur( 5px )",
        webkitBackdropFilter: "blur( 5px )",
        borderRadius: "10px",
        border: "1px solid rgba( 255, 255, 255, 0.18 )",
      }}
      elevation={3}
    >
      {/* <CustomDialog>
        <Button
          variant="contained"
          sx={{ marginLeft: "auto" }}
          endIcon={<FileUploadIcon />}
        >
          UPLOAD CSV
        </Button>
        {permissions?.map((permission) => (
          <Button key={permission} component={"a"} download>
            {permission}
          </Button>
        ))}
      </CustomDialog> */}
      <Button
        variant="contained"
        sx={{ marginLeft: "auto" }}
        endIcon={<FileUploadIcon />}
        onClick={() => setOpen(!open)}
      >
        UPLOAD CSV
      </Button>
      <Drawer anchor={"bottom"} open={open} onClose={() => setOpen(!open)}>
        <Container sx={{ height: "500px" }}>
          <Typography variant="h5" my={2}>
            Upload / Download CSV Here
          </Typography>
          <Divider />
          <Box mt={{ xs: 2, sm: 5 }} mx={"auto"} maxWidth="500px">
            {/* <Stack direction="row" alignItems="center">
              <Typography>Name here</Typography>
              <Avatar
                sx={{ bgcolor: green[500], ml: "auto" }}
                title="Upload CSV"
              >
                <IconButton aria-label="delete" sx={{ color: "white" }}>
                  <FileUploadIcon />
                </IconButton>
              </Avatar>
              <Avatar sx={{ bgcolor: blue[500], ml: 1 }} title="Download CSV">
                <IconButton aria-label="delete" sx={{ color: "white" }}>
                  <DownloadIcon />
                </IconButton>
              </Avatar>
            </Stack> */}
            {permissionActionList}
          </Box>
        </Container>
      </Drawer>
    </Grid>
  );
};

export default HomeBottomBar;
