import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";

export default function CustomTable({
  rows = [],
  columns = {},
  url = null,
  modal = {},
}) {
  return (
    <div
      style={{
        height: "80vh",
        width: "100%",
        marginTop: "0rem",
      }}
    >
      <DataGrid
        sx={{
          borderRadius: "20px",
          background: "rgba(255, 255, 255, 0.25)",
          boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
          backdropFilter: "blur( 5px )",
          webkitBackdropFilter: "blur( 5px )",
          border: "1px solid rgba( 255, 255, 255, 0.18 )",
          "& .MuiDataGrid-toolbarContainer": {
            background: "#ffffffba",
            borderRadius: "20px 20px 0 0 ",
          },
        }}
        rows={rows}
        columns={[...columns]}
        components={{
          Toolbar: GridToolbar,
          LoadingOverlay: LinearProgress,
        }}
        loading={!rows.length}
      />
    </div>
  );
}
