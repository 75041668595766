import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import CustomInput from "../../formUI/CustomInput";
import CustomCheckbox from "../../formUI/CustomCheckbox";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import axios from "axios";
import { roleUrl } from "../../utils/urls";
import { useContext } from "react";
import { GlobalContext } from "../../../cotext/GlobalContext";
import { useEffect } from "react";
import PaperCard from "../../Layout/PaperCard";
const RoleForm = ({
  modal = false,
  sx = {},
  defaultData = null,
  handleClose,
}) => {
  // const intialState = {
  //   role_name: "",
  // };
  const {
    fetchRoleList,
    role,
    setRole,
    resetRole,
    roleList,
    formDataHandler,
    onSubmitRoleForm,
  } = useContext(GlobalContext);
  useEffect(() => {
    if (defaultData) setRole(defaultData);
  }, []);

  // const [formData, setFormData] = useState(defaultData || intialState);
  // const formDataHandler = (e) =>
  //   setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    onSubmitRoleForm(e, defaultData, handleClose);
    // if (!role.role_name) alert("Please fill details");
    // e.preventDefault();
    // if (!defaultData) {
    //   axios
    //     .post(roleUrl, role)
    //     .then((res) => {
    //       resetRole();
    //       alert("Role Created!");
    //       fetchRoleList();
    //     })
    //     .catch((err) => alert("Something went wrong!"));
    // } else {
    //   axios
    //     .put(roleUrl + role.id, role)
    //     .then((res) => {
    //       handleClose && handleClose();
    //       alert("Role Updated!");
    //       fetchRoleList();
    //     })
    //     .catch((err) => alert("Something went wrong!"));
    // }
  };
  return (
    <Grid
      item
      xs={12}
      md={6}
      component={Paper}
      px={2}
      py={2}
      elevation={3}
      sx={{
        borderRadius: "20px",
        background: "rgba(255, 255, 255, 0.25)",
        boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
        backdropFilter: "blur( 5px )",
        webkitBackdropFilter: "blur( 5px )",
        border: "1px solid rgba( 255, 255, 255, 0.18 )",
        ...sx,
      }}
    >
      {!modal ? (
        <>
          <Typography variant={"h4"}>Add New Role</Typography>
          <Typography variant="body2" mb={4}>
            roles will define the role name only.
          </Typography>
        </>
      ) : null}
      <Stack
        spacing={2}
        sx={{ height: "calc(100% - 93px)" }}
        component="form"
        onSubmit={onSubmit}
      >
        <CustomInput
          label="Enter Role"
          name="role_name"
          value={role.role_name}
          onChange={(e) => formDataHandler(e, "role")}
          sx={{ mb: 2 }}
        />
        <Typography variant="body2">Roles Available</Typography>
        <Stack pb={0} sx={{ height: "45vh", overflow: "auto" }}>
          {roleList?.map((role) => (
            <Box
              sx={{ borderBottom: "1px #e7e7e7 solid", py: 1 }}
              key={role.id}
            >
              {role.role_name}
            </Box>
          ))}
        </Stack>
        <Button
          type="submit"
          variant="contained"
          endIcon={<GroupAddIcon />}
          sx={{
            py: 1,
            px: 3,
            mt: "auto",
            marginTop: "auto !important",
          }}
        >
          {!modal ? "Add New" : "Update"} Role
        </Button>
      </Stack>
    </Grid>
  );
};

export default RoleForm;
