import { Divider, Grid, TableCell, TableRow, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import CircularProgressGraph from "../../../components/formUI/CircularProgressGraph";
import CustomTable from "../../../components/formUI/CustomTable";
import PaperCard from "../../../components/Layout/PaperCard";
import RevenuePieChart from "../RevenuePieChart";

const DailyRevenue2 = ({
  dashboardData,
  getFormatCurrency,
  COLUMNS_DATA,
  TABLE_DATA,
  revenueTable,
  COLUMN_DUMMY_DATA,
}) => {
  return (
    <>
      {/* <Grid container spacing={3} pl={3} pr={2}> */}
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "grid",
          placeContent: "center",
        }}
      >
        <CircularProgressGraph value={86} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Box mb={4}>
          <Typography variant="h4" color="warning.light">
            <Typography variant="h3" component="span">
              {1200}{" "}
            </Typography>
            A.E.D
          </Typography>
          <Typography variant="body2" color="white">
            Average Daily Rate
          </Typography>
        </Box>
        <CustomTable data={TABLE_DATA} sx={{ minWidth: "auto" }}>
          {COLUMNS_DATA.map((col, index) => (
            <TableRow
              key={index}
              sx={{
                "& *": { color: "white" },
              }}
            >
              <TableCell>{col}</TableCell>
              <TableCell>
                AED{" "}
                {revenueTable
                  ? getFormatCurrency(Object.values(revenueTable)[index] || 100)
                  : 0}
              </TableCell>
            </TableRow>
          ))}
          <TableRow></TableRow>
        </CustomTable>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={6} height="500px">
        <RevenuePieChart />
      </Grid>
      <Grid item xs={6}>
        {/* <PaperCard sx={{ padding: "0 1rem 0" }}> */}
        <CustomTable
          columns={COLUMN_DUMMY_DATA[0]}
          data={TABLE_DATA}
          sx={{
            "& *": { color: "white" },
          }}
        >
          {TABLE_DATA?.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.title}</TableCell>
              <TableCell>{row.mtd_actual}</TableCell>
              <TableCell>{row.mtd_budget}</TableCell>
              <TableCell>{row.mtd_daily}</TableCell>
            </TableRow>
          ))}
        </CustomTable>
        {/* </PaperCard> */}
      </Grid>
      {/* </Grid> */}
    </>
  );
};

export default DailyRevenue2;
