import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { Stack } from "@mui/system";
import { Avatar } from "@mui/material";

import BrandPng from "../../assets/images/brand.png";
import NavList from "./NavList";
import { useContext } from "react";
import { GlobalContext } from "../../cotext/GlobalContext";
import { useLocation, useNavigate } from "react-router";
import { Helmet } from "react-helmet";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Layout({ children }) {
  const navigate = useNavigate();
  const { getProfile } = useContext(GlobalContext);
  const location = useLocation();
  const appTitle = location.pathname
    .slice(1)
    .replaceAll("/", " / ")
    .toUpperCase();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <Helmet>
        <title>{"BI | " + appTitle}</title>
      </Helmet>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{
          background: "linear-gradient(-90deg,#deeffe, #f2faff)",
          color: "rgba(0, 0, 0, 0.87)",
          boxShadow: "none",
          borderBottom: "1px gray solid",

          "& .MuiToolbar-root": {
            minHeight: "50px",
          },
        }}
      >
        <Toolbar sx={{}}>
          <Box
            display="flex"
            justifyContent={"space-between"}
            alignItems="center"
            sx={{ width: "100%" }}
            s
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              color={"primary.main"}
              mr={1}
              sx={{
                opacity: { xs: 0, md: 1 },
              }}
            >
              {appTitle}
            </Typography>
            <Stack
              direction={"row"}
              spacing={2}
              ml="auto"
              alignItems={"center"}
              onClick={() => navigate("/user/profile")}
            >
              <Avatar
                alt={getProfile()?.username}
                src="/static/images/avatar/1.jpg"
                sx={{ width: 30, height: 30 }}
              />
              <Stack spacing={0} sx={{ display: { xs: "none", sm: "flex" } }}>
                <Typography variant="subtitle1" mb={0} lineHeight={1}>
                  {" "}
                  {getProfile()?.username}
                </Typography>
                <Typography variant="body2">{getProfile()?.email}</Typography>
              </Stack>
            </Stack>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          position: "absolute",

          "& .MuiPaper-root > *:nth-child(1)": {
            minHeight: "50px",
          },
          "& .MuiPaper-root": {
            height: "70%",
            borderRadius: "20px",
            marginLeft: "1rem",
            background: "linear-gradient(45deg, #ffffffbf, #a6d3ff)!important",
            marginTop: "7%",
            background: "rgba( 0, 117, 255, 0.25 )",
            boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
            backdropFilter: "blur( 5px )",
            webkitBackdropFilter: "blur( 5px )",
            borderRadius: "10px",
            border: "1px solid rgba( 255, 255, 255, 0.18 )",
            "& .MuiListItemText-root": {
              opacity: 0,
              position: "relative",
              left: 0,
              transition: "0.3s",
              "&,& *": {
                color: "white",
              },
            },
            "&:hover": {
              width: "200px",
              "& .MuiListItemText-root": {
                opacity: 1,
                left: "1rem",
              },
            },
          },
        }}
        className="nav-wrapper"
      >
        <DrawerHeader sx={{ justifyContent: "space-between" }}>
          <Stack spacing={2} direction="row" alignItems="center" ml={1}>
            <img src={BrandPng} alt="" width="40px" />
            <Typography color={"primary"} fontSize="1rem">
              Vitesse
            </Typography>
          </Stack>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <NavList open={open} setOpen={setOpen} />
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          ml: { md: "0rem", xs: "73px" },
          mt: "50px",
        }}
      >
        {/* <DrawerHeader /> */}
        <Box
          sx={{
            // marginLeft: "65px",
            pt: "40px",

            paddingLeft: "6rem !important",
            paddingRight: "1rem !important",
            // background: `linear-gradient(180deg, #0b2b4b 0%, #1976d2 60%)`,
            // background: `linear-gradient(180deg, #0b2b4b 0%, #1976d2 60%)`,
            background: "linear-gradient(180deg, #8bc4ff 0%, #3c92e8 60%)",
            minHeight: "calc(100vh - 50px)",
            backgroundAttachment: "fixed",
            backgroundSize: "100% 100vh",
            "& *": {
              // color: "white !important",
            },
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}
