import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PeopleIcon from "@mui/icons-material/People";
import { useContext } from "react";
import { GlobalContext } from "../../cotext/GlobalContext";
import { blue } from "@mui/material/colors";
const NavList = ({ open, setOpen }) => {
  const { deleteProfile, isSuperAdmin } = useContext(GlobalContext);

  const navlist = [
    {
      link: "/dashboard",
      name: "Dashboard",
      icon: <HomeIcon />,
      isSuperAdmin: true,
    },
    {
      link: "/user/profile",
      name: "User Profile",
      icon: <AccountCircleIcon />,
      isSuperAdmin: true,
    },
    {
      link: "/users",
      name: "Users",
      icon: <PeopleIcon />,
      isSuperAdmin: isSuperAdmin(),
    },
    {
      link: "/roles",
      name: "Roles",
      icon: <ManageAccountsIcon />,
      isSuperAdmin: isSuperAdmin(),
    },
    {
      link: "/configuration",
      name: "Configuration",
      icon: <SettingsIcon />,
      isSuperAdmin: isSuperAdmin(),
    },
  ];
  const navigate = useNavigate();
  const location = useLocation();

  const logoutHandler = () => {
    deleteProfile();
    navigate("/");
  };

  return (
    <List>
      {navlist.map(({ link, icon, name, isSuperAdmin }) =>
        isSuperAdmin ? (
          <ListItem
            key={link}
            disablePadding
            sx={{
              display: "block",
              backgroundColor: location.pathname === link && blue[800],
            }}
            title={name}
          >
            <ListItemButton
              onClick={() => {
                navigate(link);
                setOpen((prevOpen) => (prevOpen ? !prevOpen : prevOpen));
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                color: location.pathname === link && "white",

                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  color: location.pathname === link && "white",
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText primary={name} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        ) : null
      )}
      <ListItem disablePadding sx={{ display: "block" }}>
        <ListItemButton
          onClick={() => logoutHandler()}
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          title={"Log Out"}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
            }}
          >
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={"Log Out"} sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </ListItem>
    </List>
  );
};

export default NavList;
