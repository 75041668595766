import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const CircularProgressGraph = ({ value = 0 }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // pt: 3,
        // pb: 2,
        position: "relative",
        // transform: { xs: "scale(0.8)", md: "scale(1)" },
        transition: "0.3s",
        "&:hover": {
          transition: "0.5s",
          transform: "scale(1.1)",
        },
      }}
    >
      <CircularProgress
        variant="determinate"
        value={value}
        size={250}
        color="warning"
        sx={{
          "& svg": {
            fill: "warning.light",
            color: "warning.light",
            "& circle": {
              strokeWidth: 2,
              transition: "1s",
              filter: "drop-shadow(0px 0px 1px #f57c00)",
            },
          },
        }}
      />
      <Box
        sx={{
          position: "absolute",
          left: "50%",
          top: "55%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <Typography
          variant="h2"
          color="white"
          sx={{
            textShadow: "1px 1px 15px #f57c00 ",
          }}
        >
          {value}%{" "}
        </Typography>
        <Typography
          variant="body1"
          color="white"
          sx={{
            textShadow: "1px 1px 15px #f57c00 ",
          }}
        >
          Occupancy{" "}
        </Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressGraph;
