import React, { Fragment, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./style.css";
import { line, area } from "d3-shape";
import { Bar } from "@nivo/bar";
import { Box } from "@mui/system";
import { useMediaQuery } from "@mui/material";

const barColor = "#86cfffbf";
const lineColor = "#1f2d36";
const areaColor = "#1d2c32";

// `v` is used for bars
// `v1` is used for line
// `v2` is used for area
const data = [
  { x: "A", v: 3.3, v1: 2.0, v2: 2.0 },
  { x: "1", v: 3.5, v1: 3.1, v2: 3.1 },
  { x: "2", v: 3.8, v1: 2.3, v2: 2.3 },
  { x: "3", v: 4.1, v1: 3.1, v2: 3.1 },
  { x: "4", v: 4.4, v1: 4.0, v2: 4.0 },
  { x: "5", v: 4.7, v1: 3.9, v2: 3.9 },
  { x: "6", v: 4.9, v1: 2.9, v2: 2.9 },
  { x: "7", v: 5.2, v1: 3.3, v2: 3.3 },
];

const Area = ({ bars, xScale, yScale, height }) => {
  const areaGenerator = area()
    .x((bar) => xScale(bar.data.indexValue) + bar.width / 2)
    .y0(() => height)
    .y1((bar) => {
      return yScale(bar.data.data.v2);
    });

  return (
    <path
      d={areaGenerator(bars)}
      fill={areaColor}
      style={{
        mixBlendMode: "",
        pointerEvents: "none",
        background: "red",
        display: "block",
        position: "relative",
        // transform : 'translateY(-46px)'
      }}
      opacity={0.5}
    />
  );
};

const Line = ({ bars, xScale, yScale }) => {
  const lineGenerator = line()
    .x((bar) => xScale(bar.data.indexValue) + bar.width / 2)
    .y((bar) => yScale(bar.data.data.v1));

  return (
    <Fragment>
      <path
        d={lineGenerator(bars)}
        fill="none"
        stroke={lineColor}
        style={{ pointerEvents: "none" }}
      />
      {bars.map((bar) => (
        <circle
          key={bar.key}
          cx={xScale(bar.data.indexValue) + bar.width / 2}
          cy={yScale(bar.data.data.v1)}
          r={4}
          fill="white"
          stroke={lineColor}
          style={{ pointerEvents: "none" }}
        />
      ))}
    </Fragment>
  );
};

const AdrMixGraph = () => {
  const isMobile = useMediaQuery("(max-width : 600px)");
  function getSize() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Box
      sx={{
        maxWidth: { xs: "90vw", md: "auto", overflow: "auto", margin: "auto" },
      }}
    >
      <Bar
        // keys={1 == 1 ? ["Average", "Revenue", "Occupancy"] : ["Total Revenue","GOP","NOP"]}

        width={
          isMobile ? "500" : windowSize.width - (10 * windowSize.width) / 100
        }
        height={400}
        data={data}
        keys={["v"]}
        maxValue={6}
        padding={0.6}
        margin={{
          top: 10,
          right: 10,
          bottom: 36,
          left: 36,
        }}
        indexBy="x"
        enableLabel={false}
        colors={[barColor]}
        borderRadius={2}
        axisLeft={{
          tickValues: 7,
        }}
        layers={["grid", "axes", "bars", Area, Line, "markers", "legends"]}
      />
    </Box>
  );
};

// ReactDOM.render(<App />, document.getElementById("root"));
export default AdrMixGraph;
