import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../cotext/GlobalContext";
import CustomTable from "../../Layout/CustomTable";
import ActionPanel from "../../Layout/CustomTable/ActionPanel";
import { userUrl } from "../../utils/urls";
import UserForm from "../UserForm";

export default function UserList() {
  const { userList, fetchUserList, fetchRoleList, accessHandler } =
    useContext(GlobalContext);

  var columns = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "username",
      headerName: "Name",
      width: 130,
    },
    {
      field: "email",
      headerName: "Email",
      editable: false,
      width: 130,
    },
    {
      field: "role_name",
      headerName: "Role Name",
      editable: false,
      width: 150,
      // renderCell: ({ row }) => row.role.role_name,
    },
    {
      field: "permissions",
      headerName: "Permissions",
      editable: false,
      minWidth: 700,

      flex: 1,
      renderCell: (params) => {
        return accessHandler(params.row).join(" | ") || [];
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => {
        return (
          <ActionPanel
            url={"users"}
            id={params.row.id}
            modal={UserForm}
            name={params.row.user_name}
            data={params.row}
          />
        );
      },
    },
  ];
  useEffect(() => {
    fetchUserList();
    fetchRoleList();
  }, []);

  return (
    <CustomTable
      rows={userList}
      url="users"
      columns={columns}
      modal={UserForm}
    />
  );
}
